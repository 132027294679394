<template>
  <div class="roleTab clearfix">
    <el-button type="primary" @click="wxOrder" plain>外协订单入库</el-button>
    <el-button type="primary" @click="gylOrder" plain>供应链订单入库</el-button>
    <el-button type="primary" @click="ptwlOrder" plain>普通物料入库</el-button>
  </div>
</template>

<script>
// import { request } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
export default {
  components: Component.components,
  name: 'AddMaterielInLibraryTable',
  props: [],
  data () {
    return {

    }
  },
  created () {
  },
  mounted () {

  },
  methods: {
    // 外协订单入库
    wxOrder () {
      this.$router.push('/InventoryDelivery/AddMaterielInLibrary?' + 'type=' + 1)
    },
    // 供应链订单入库
    gylOrder () {
      this.$router.push('/InventoryDelivery/AddMaterielInLibrary?' + 'type=' + 2)
    },
    // 普通物料入库
    ptwlOrder () {
      this.$router.push('/InventoryDelivery/AddMaterielInLibrary?' + 'type=' + 3)
    }
  },
  watch: {
  }
}
</script>
<style scoped>
</style>
